import React from "react";
import styled from "styled-components";
import { useResponsive } from "../../Components/responsive";
import Balancer from "react-wrap-balancer";

function HomeContentDesktop() {
  const { isXsm, isSm, isMd, isLg, isXl, is2Xl } = useResponsive();

  const videoSrc = isXsm || isSm ? "/videos/demo4-5.mp4" : "/videos/demo.mp4";

  return (
    <HomeContentContainer>
      <SectionOne
        $isXsm={isXsm}
        $isSm={isSm}
        $isMd={isMd}
        $isLg={isLg}
        $isXl={isXl}
        $is2Xl={is2Xl}
      >
        <h2>
          <Balancer>
            <GradientText>We may scream</GradientText> and cry sweat, but we
            believe that with adaptable workflows and globally positioned
            artists, every constraint has{" "}
            <ReversedGradientText> a creative solution</ReversedGradientText>.
          </Balancer>
        </h2>
      </SectionOne>
      <SectionTwo>
        <StyledVideo
          className="background-video"
          playsInline
          autoPlay
          loop
          muted
          controls={false}
        >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </SectionTwo>
    </HomeContentContainer>
  );
}

const HomeContentContainer = styled.div`
  max-width: 1440px;
`;

const SectionOne = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  color: #000;
  margin: 0 0 10rem 0;
  h2 {
    text-align: center;
    font-weight: 600;
    font-weight: 900;
    font-size: ${(props) =>
      props.$isXsm
        ? "2rem"
        : props.$isSm
        ? "2.5rem"
        : props.$isMd
        ? "3rem"
        : props.$isLg
        ? "3.5rem"
        : props.$isXl
        ? "4rem"
        : props.$is2Xl
        ? "5rem"
        : "5rem"};
    width: ${(props) =>
      props.$isXsm
        ? "90%"
        : props.$isSm
        ? "90%"
        : props.$isMd
        ? "80%"
        : props.$isLg
        ? "60%"
        : props.$isXl
        ? "60%"
        : props.$is2Xl
        ? "80%"
        : "80%"};
  }
`;

const GradientText = styled.span`
  background: linear-gradient(
    90deg,
    #22577a,
    #246e8c,
    #2f869c,
    #439da8,
    #5eb5b2,
    #7dccbb,
    #a1e3c3,
    #c7f9cc
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const ReversedGradientText = styled.span`
  background: linear-gradient(
    to left,
    #22577a,
    #246e8c,
    #2f869c,
    #439da8,
    #5eb5b2,
    #7dccbb,
    #a1e3c3,
    #c7f9cc
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SectionTwo = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10rem 0;
  width: 100%;
`;

const StyledVideo = styled.video`
  width: 80%;
  height: auto;
  border-radius: 8px;
`;

export default HomeContentDesktop;
