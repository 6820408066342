import React from "react";
import styled from "styled-components";
import Layout from "../Layout/Layout";
import Button from "@mui/material/Button";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 1.5rem;
  width: 60%;
  margin-bottom: 2rem;
`;

const CustomButton = styled(Button)`
  border: 2px solid black !important;
  color: white !important;
  color: black !important;
  font-weight: 600 !important;
  &:hover {
    background-color: black !important;
    color: white !important;
  }
`;
const Contact = () => (
  <Layout>
    <PageContainer>
      <Title>Contact</Title>
      <Content>
        Reach out to our production team for a test, quote or any enquiry.
      </Content>
      <CustomButton variant="outlined" href="mailto:sj@positivechaosvfx.com">
        Mail Production
      </CustomButton>
    </PageContainer>
  </Layout>
);

export default Contact;
