import React from "react";
import { useVideoTexture, useMask } from "@react-three/drei";

function VideoPlane(props) {
  const texture = useVideoTexture("/videos/video2.mp4", {
    start: true,
    muted: true,
  }); // Ensure video starts and is muted
  const stencil = useMask(1, false); // Using the stencil mask

  return (
    <mesh {...props}>
      <planeGeometry args={[5, 3]} /> {/* Adjust the size as needed */}
      <meshBasicMaterial
        map={texture}
        toneMapped={false}
        stencilWrite={stencil.stencilWrite}
        stencilRef={stencil.stencilRef}
        stencilFunc={stencil.stencilFunc}
        stencilFail={stencil.stencilFail}
        stencilZFail={stencil.stencilZFail}
        stencilZPass={stencil.stencilZPass}
      />
    </mesh>
  );
}

export default VideoPlane;
