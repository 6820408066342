import React from "react";
import styled from "styled-components";
import Layout from "../Layout/Layout";
import Balancer from "react-wrap-balancer";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 1.5rem;
  width: 60%;
`;

const About = () => (
  <Layout>
    <PageContainer>
      <Title>About Us</Title>
      <Content>
        <Balancer>
          Founded by Editors Patric Ryan, Matthew Pochettino and Producer SJ
          O'Mara, Positive Chaos is a boutique post production studio born in
          the edit suite.
        </Balancer>
      </Content>
      <Content>
        <Balancer>
          No matter the format or forum we place our efforts in shots which best
          serve the story, scratching the itch of the wishlist shots. Shots that
          often strengthen storytelling more than post production budgets will
          allow.
        </Balancer>
      </Content>
    </PageContainer>
  </Layout>
);

export default About;
