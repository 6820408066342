import React from "react";
import styled from "styled-components";

const Logo = ({ fill = "#fff", size = "2rem" }) => {
  return (
    <LogoContainer fill={fill} size={size}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 639.09 87.2"
      >
        <g
          fill={fill}
          data-name="Group 1"
          transform="translate(-7326.51 -198.3)"
        >
          <path
            d="M7350.61 283.9l-16.1.8a70.841 70.841 0 01-8-10c.6-21.2.2-53.3 1.5-74.6 4.9.1 19.2.6 22.6 1.2 2.2.4 16 6.8 17 15a68.663 68.663 0 01.4 8.1 135.616 135.616 0 01-1.5 19.2c-.6 4-6 8.9-12.7 8.9h-.8l-2.4 31.4zm-12.3-10l.5-30.2 6.6-.5c4-.9 8.7-3.7 9.3-7.9a147.393 147.393 0 001.5-18.8 64.348 64.348 0 00-.5-7.9 8.52 8.52 0 00-5.9-5.1 127.145 127.145 0 00-19.9-1.3c-1.3 22.6-.1 48.6-1.7 71.5l10.1.2zm9-52.3c0 4.8-.7 9.4-1.6 10.7-1 1.3-3.4 1.7-5.4 1.7-.5 0-1-.1-1.4-.1 0-7.4-.5-16.8-.5-23.4 2.2 0 7.2-.2 8.1 3.2a32.007 32.007 0 01.8 7.9z"
            data-name="Path 1"
          ></path>
          <path
            d="M7375.71 244.6v-9.8c.1-17 .6-27.5 7.2-32.4 1.6-1.2 6-2.4 11.4-2.4a38.892 38.892 0 017.4.8c7.4 1.6 21.2 5.1 21.9 18.6.3 5.5.4 12.1.4 18.7 0 14.8-.6 29.9-1.2 34-1.4 8.8-5.1 11.6-18.4 12.1h-.6c-8 0-15.1-5.4-22.1-9.5-5.4-3.2-6-17.7-6-30.1zm33.7-22.8v-4.5c0-8.3-3.7-11.2-6.1-13-2-1.5-5.5-2.1-9-2.1-4 0-7.9.8-9.5 2-4.7 1.8-6.1 8.3-6.6 15.5-.3 4.6-.2 9.5-.3 13.8-.1 3.2-.1 6.7-.1 10.2 0 9.5.4 19.2 2 24.2 2 6 7.6 7.1 12 7.1.8 0 1.5-.1 2.2-.1a11.891 11.891 0 009.7-5.1c5.4-6.7 5.7-34.4 5.7-48zm-10.5 3.3c0 10.7-.7 23.7-1.3 32.7-.1 2.4-1.2 7.7-5.7 7-2.6-.4-3.8-1.8-3.9-5.3-.2-7.3-.4-13.9-.4-20.4 0-7.5.2-14.9.7-22.8.5-2.7 1.7-3.9 4.2-5 2.8-.1 5.9 0 6.1 3.9.2 2.9.3 6.3.3 9.9z"
            data-name="Path 2"
          ></path>
          <path
            d="M7428.91 265.8c4.8-1.9 9.7-3.3 11.5-9.4 1.5-5.2-2.4-11.7-4.6-18.2a52.154 52.154 0 01-3.1-16.5c0-6.6 2-11.3 4.8-14.6 6-6.9 12.4-7.7 16.7-8.8 3.2 1.1 7.8 2.8 11 3.8l5.1 12.6c-5 .8-10.4 4.7-11.7 7.1-1.7 3.2-.8 9.1 1.9 14.9 3.4 7.2 6 16.1 6 24.1s-2.6 15-9.6 18.6a77.945 77.945 0 01-14.1 5.6l-10.6-7-3.3-12.2zm5.2 10.3c3.2-.6 5.2-1.6 9-3.3 7-3.1 10.1-9.8 10.1-17.5a38.963 38.963 0 00-4.2-16.8c-2.2-4.3-4.3-10.4-4.3-15.6 0-3.2.8-6.1 2.9-8.2a23.771 23.771 0 019.5-5.2l-3.7-8.7c-4.6 1.3-9.6 3.7-12.4 6.6a20.277 20.277 0 00-5.9 14.8c0 4.8 1.3 10.1 3.2 15.9 2.9 8.7 5.7 13.4 4.8 19s-6.5 8.4-11.3 10.3z"
            data-name="Path 3"
          ></path>
          <path
            d="M7477.91 276.7c-1.2-20.9-2.1-50.2-2.5-76.2 5.1-.6 10.5-.8 13.3-1.1 3.2.8 7.1 1.6 12.3 3-.6 24.5-.6 57.1-1.6 82.71l-16.4-.6-5.1-7.8zm.1-73.6c.3 13.3.8 26.5 1 39.8.2 9.7.6 22 .8 31.2l9 .6c-.5-22.5-1.1-49.9-1.6-72.4-3.8.5-6.6.5-9.2.8z"
            data-name="Path 4"
          ></path>
          <path
            d="M7521.21 276c-1.2-17.6-2.1-38.6-2.5-53.5l-6.2-.4c-1.7-3.6-3.4-7.4-4.6-10.3 0-2.9.2-8.4.3-10.8 5.3-1 27.1-2 35.1-2h1a81.545 81.545 0 018.2 5.3c-.1 3.6-.5 10.9-.9 18.5h-7.6c-.4 7-.1 13.6-.1 24.6 0 9.2-.3 20.9-.1 37.5l-17.6-1-5-7.9zm11.1-2.7c0-11-.5-21.6-.9-30.5-.6-12.4-.8-21-1-31.8 4.1-.2 9.7-.2 12.9-.5.1-1.8.1-3.2.1-4.4a48.013 48.013 0 00-.2-5c-9.6.4-23.3 1.2-32.8 1.9 0 1.8.2 5.8.2 8.8 2.4 0 6.2-.4 10.2-.4.8 20.4 1.5 41.8 2.4 62.7l9.1-.8z"
            data-name="Path 5"
          ></path>
          <path
            d="M7561.5 276.7c-1.2-20.9-2.1-50.2-2.5-76.2 5.1-.6 10.5-.8 13.3-1.1 3.2.8 7.1 1.6 12.3 3-.6 24.5-.6 57.1-1.6 82.71l-16.4-.6-5.1-7.8zm.1-73.6c.3 13.3.8 26.5 1 39.8.2 9.7.6 22 .8 31.2l9 .6c-.5-22.5-1.1-49.9-1.6-72.4-3.8.5-6.6.5-9.2.8z"
            data-name="Path 6"
          ></path>
          <path
            d="M7594.4 216.7l-3.9-13.5 12.8-3.3 12.5 3.2c1.1 7.2 2.1 14.4 3.3 23.2 1.3-7.4 2.9-15.3 5.3-27.5l14.7 2.7 6.1 5c-2.3 22.1-4.7 51.8-7.8 75.7l-20.7 2.1-5.1-9.5-17.2-58.1zm16.8 15.7c-3.5-11.5-5.8-20.7-8.8-30.3l-9.1 2.4 20.5 69.2 8.2.1c4.5-23.4 10.1-49.6 14.3-70.2l-10.1-2c-2.8 13.5-6.7 33.1-9.7 49.8-1.7-6.2-3.4-12.9-5.3-19z"
            data-name="Path 7"
          ></path>
          <path
            d="M7675.6 230.6l2.5.1c2.5 1.7 5.5 3.8 10.2 6.9-.3 3.5-.2 7.7-.5 10.9-6 1.4-10.2 2.6-13.6 3.6-.3 2.1-.3 5.7-.5 8.7 2.5.5 5.1 1 6.9 1.3a67.592 67.592 0 019.6 4.6c.3 6.1.9 11.6 1 16.9-11.2.6-25.1 1.3-35.9 1.9-1.3-2.5-4.6-8.1-6-11.4 0-17.8 1.5-61.9 1.9-74.8 7.9-.5 19.8-.5 28.9-.5a56.479 56.479 0 0110.6 6.7c-.7 5.4-1.3 10.4-1.9 14.9l-12.4-.7-.8 10.9zm-12.4-20c5.7-.7 10.5-.9 16-1.6 0-2.6 0-6.2.1-7.5-3.3-.1-6.4-.1-9.5-.1-5.7 0-11.1.1-16.3.3-.3 21.8-1.5 48-1.8 70.5 4.1.3 9.1.4 14.1.4 4.6 0 9.1.1 13.2.1a16.934 16.934 0 00.1-2.2c0-1.8-.3-3.4-.3-5 0-.4.1-.8.1-1.2-4.9-.5-11.9-1.4-16.7-2 .3-6.1.2-14.5.5-20.3l13.5-1.5a26.332 26.332 0 00.3-3.8 25.457 25.457 0 00-.3-4.1c-5.2.2-10 .5-13.6.7l.6-22.7z"
            data-name="Path 8"
          ></path>
          <path
            d="M7751.1 284.7c-21.4 0-32.1-8.6-35.2-23.6-.8-4-2.8-7.9-3-27.9 0-2.7-.1-5.3-.1-7.6 0-13.2 1.2-20.1 11.5-23.8a37.661 37.661 0 0112.5-2.3 22.35 22.35 0 013.7.2c2 1 10.2 2.9 12.2 4.2-.5 6.2-.6 10.4-1.1 14.1-7.6 1-12.5.1-13 10.1-.5 10.5.1 18 1.5 33.6a47.191 47.191 0 006.3.3c1.8 1.2 5.9 2.3 7.9 3.8-.1 4.2-1 13.4-1.4 18.9h-1.8zm-12.4-83.2a44.246 44.246 0 00-12.4 1.7c-5.6 1.8-9.7 6.2-10.5 10.3a78.448 78.448 0 00-1 13.9c0 13.5 1.9 29.8 4.3 36.4 3.1 8.8 15.5 10.5 25.1 10.5h.9a76.76 76.76 0 00-.5-10.4c-10.8.4-18-1.1-18.6-10.4-.6-8.9-1.6-16.6-1.6-23.8a55.5 55.5 0 01.8-10c1.4-7.1 12.3-7.5 13.6-7.5l-.1-10.7z"
            data-name="Path 9"
          ></path>
          <path
            d="M7784.8 284.1c-3.6 0-15.2-.6-19.3-.6l-4.7-8.6-1.1-56.8.1-18.2c1.7 0 10-.2 13-.4 2.6 1.6 6.2 4.8 9.9 7.6 0-2.2 0-5.5.2-7.4 1.6 0 3.2-.1 4.5-.1 4 0 6.7.2 8 .2a80.714 80.714 0 019.2 6c.9 23.7 2 51.5 3.7 77.5l-16.4 1-7.3-8.5.2 8.3zm6.5-82.6c-2.3 0-4.9.1-6.3.1-.4 5.8-.4 18.5-.7 29.5-2.8-.2-7.4.1-11.5-.3-.2-10.4-.9-19.4-1.7-29h-9l-.1 9.6.6 61.9a26.271 26.271 0 013-.1c2.3 0 5.1.1 6.7.1 0-9.5.6-14.4.5-20-.1-4.9-.5-9.6-.5-12.8a82.653 82.653 0 0112.2 0c-.8 11.7 0 21.9 0 32.8l8.9.2v-33.1c0-13.1.1-26 .9-38.8a27.185 27.185 0 00-3-.1z"
            data-name="Path 10"
          ></path>
          <path
            d="M7832.1 200c2.8-.3 8-.4 12.1-.5l14.9 3.8c3.1 21 6.4 44 11.1 78.21-7.5.8-16.3 2.8-20.4 2.8h-.5l-5.1-8.9c-.8-2.4-1.3-5-1.7-6.5l-5.6.5-2.8 13.6c-4.4.5-12.8 1.1-17.2 1.2l-4.1-10.6c6.4-25.7 12.2-48.7 19.3-73.6zm-8.4 73.9c1.3-4.1 3.3-9.6 4.4-14 5.7.2 9.9.1 15.1.2.8 3.1 2.2 9.4 3.4 14.2 3.5-.6 7.4-1.3 10.3-2-5.4-24.1-8.5-51.4-12.9-70.5-2.6.1-7.9.4-10.3.8-6.1 23.5-12.5 46.4-18.3 70.4a62.023 62.023 0 008.3.9zm6.5-22.8c1.8-6 4.7-20.3 7.3-29.7 1.8 9.6 2.8 18.9 4.6 30.4z"
            data-name="Path 11"
          ></path>
          <path
            d="M7875.1 235.6c-.3-5.2-.6-10-.6-14.2 0-9.7 1.3-16.7 5.6-19.8 2-1.4 5.7-3.3 11.7-3.3a34.6 34.6 0 014.3.3c7.5 1.1 25.2 5.8 25.5 19.3.2 8.3.4 16.5.4 24.4a233.8 233.8 0 01-1.4 28.4c-1.3 11.1-5.5 13.3-21.5 13.4-3.5 0-14.4-4.9-18.9-11.6-2.9-4.3-4.3-23.9-5.1-36.9zm33.8 10.2c0-10.5-.9-22.2-1.3-30.9-.4-8.2-4.5-11.4-7-13-1.7-1.1-4.3-1.5-7.1-1.5-4.6 0-9.6 1.2-11.4 2.7-4.5 3-5.5 9.8-5.5 17.2 0 4.7.4 9.6.6 13.9.5 12 1.2 27 3.6 33.9 1.8 5.3 7.5 6.5 12 6.5a32.5 32.5 0 003.3-.2c4.9-.7 7.1-2.6 9.3-5.8 2.7-3.9 3.5-12.8 3.5-22.8zm-11.4-4.7c0 5.8-.1 11.4-.2 16 0 2.4-.8 6.8-4.2 6.9-2.6.1-4.1-.8-4.4-4.3a431.229 431.229 0 01-1.6-44.1c.4-2.7 1.5-3.9 4-5.1 2.8-.2 5-.2 5.4 3.7.7 7 1 17.3 1 26.9z"
            data-name="Path 12"
          ></path>
          <path
            d="M7926.8 266.4c4.9-1.9 9.7-2.8 11.4-8.8 1.4-5.2-3.9-12.1-6.3-18.4a48.539 48.539 0 01-3.7-17.3c0-6.1 1.8-10.7 4.4-13.8 6-6.8 12.4-7.7 16.8-8.9 3.9.9 10.8 1.5 14.1 2.4l2.1 13.2c-5.1 1-9.7 4.7-11 7-1.7 3.2-.5 8.9 2.4 14.4a57.063 57.063 0 016.5 25.5c0 7.8-2.2 14.5-7.7 17-4.6 2.2-11 5.1-17.9 6.4l-7.3-6.8-3.8-11.9zm5.7 9.3c3.3-.7 4.9-1.5 8.7-3.2 6.3-2.9 8.7-8.7 8.7-15.3 0-6.1-2-12.8-4.9-19-2.1-4.5-4.5-10.5-4.5-15.6a10.428 10.428 0 012.7-7.4c1.5-1.5 4.5-2.9 9-4.4l-2.4-9.2a24.265 24.265 0 00-13.6 6.7 18.927 18.927 0 00-5.7 14.1c0 5.3 1.5 10.6 3.8 16.6 3.2 8.3 7.5 13.1 6.7 18.7-.8 5.4-6.4 8.1-11.1 10.2l2.6 7.8z"
            data-name="Path 13"
          ></path>
        </g>
      </svg>
    </LogoContainer>
  );
};

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default Logo;
