import React from "react";
import styled from "styled-components";
import Logo from "../Components/Logo";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  return (
    <FooterContainer>
      <LogoContainer>
        <LogoWrapper>
          <Logo fill="#000" size="200px" />
        </LogoWrapper>
      </LogoContainer>
      <SocialIcons>
        <a
          href="https://instagram.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon className="social-icon" />
        </a>
        <a
          href="https://linkedin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon className="social-icon" />
        </a>
      </SocialIcons>
      <NavLinks>
        <a href="/contact">Contact</a>
        <a href="/about">About Us</a>
        {/* <a href="/services">Services</a>
        <a href="/policy">Policy</a> */}
      </NavLinks>
      <FooterBottom>
        <p>
          &copy; {new Date().getFullYear()} Positive Chaos Studio. All rights
          reserved.
        </p>
      </FooterBottom>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  .social-icon {
    margin: 0 10px;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #0073b1; /* LinkedIn color */
    }

    &:nth-child(2):hover {
      color: #e1306c; /* Instagram color */
    }
  }
`;

const NavLinks = styled.div`
  margin-bottom: 20px;

  a {
    margin: 0 15px;
    color: #333;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const FooterBottom = styled.div`
  font-size: 14px;
  color: lightgray;
`;

export default Footer;
