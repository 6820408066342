import React, { useState, useRef, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Float, MeshTransmissionMaterial, Preload } from "@react-three/drei";
import { useMediaQuery } from "react-responsive";
import VideoPlane from "./VideoPlane";
import { ModelProvider } from "../../Models/ModelLoader";
import { useModel } from "../../Models/ModelLoader";
import styled from "styled-components";

const CanvasWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh; /* Make the canvas take full viewport height */
  overflow: hidden; /* Ensure no overflow */
`;

function cubicEase(t) {
  return t * t * (3 - 2 * t);
}

function lerp(a, b, t) {
  return a + (b - a) * t;
}

function lerp3(start, end, t) {
  const tEased = cubicEase(t);
  return [
    lerp(start[0], end[0], tEased),
    lerp(start[1], end[1], tEased),
    lerp(start[2], end[2], tEased),
  ];
}

function Splash() {
  const [perfSucks, degrade] = useState(false);
  const bgColor = "#fff";
  const cameraRef = useRef(null);
  const mouseRef = useRef({ x: 0, y: 0 });

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const initialZ = isMobile ? 25 : 14;

  const handleMouseMove = (event) => {
    mouseRef.current = {
      x: (event.clientX / window.innerWidth) * 2 - 1,
      y: -(event.clientY / window.innerHeight) * 2 + 1,
    };
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <CanvasWrapper>
      <ModelProvider>
        <Canvas
          shadows
          dpr={[1, perfSucks ? 1.5 : 2]}
          camera={{ position: [0, 0, initialZ], fov: 35, near: 1, far: 45 }}
          style={{ width: "100%", height: "100%" }}
          onCreated={({ camera }) => {
            cameraRef.current = camera;
            camera.position.set(0, 0, initialZ);
          }}
        >
          <color attach="background" args={[bgColor]} />
          <directionalLight position={[1, 1, -10]} castShadow intensity={1} />
          <directionalLight
            position={[-4, 1, -2]}
            color={"lightskyblue"}
            castShadow
            intensity={0.3}
          />
          <PerformanceMonitor onDecline={() => degrade(true)} />
          <Float rotationIntensity={2} floatIntensity={2} speed={2}>
            <Logo position={[0, 0, 0]} rotation={[0, 0, 0]}>
              <VideoPlane position={[0, 0, 0]} rotation={[0, 0, 0]} scale={3} />
            </Logo>
          </Float>

          <Preload all />
          <CameraControls
            mouseRef={mouseRef}
            perfSucks={perfSucks}
            initialZ={initialZ}
          />
        </Canvas>
      </ModelProvider>
    </CanvasWrapper>
  );
}

function PerformanceMonitor({ onDecline }) {
  useFrame(({ gl, scene, camera }) => {
    // Uncomment and adjust the condition as needed for performance monitoring
    // if (false) { onDecline(); }
  });
  return null;
}

function CameraControls({ mouseRef, perfSucks, initialZ }) {
  const { camera } = useThree();
  const targetFocusRef = useRef([0, 0, 0]);

  useFrame(() => {
    if (!perfSucks) {
      const targetPosition = {
        x: mouseRef.current.x * 5,
        y: mouseRef.current.y * 5,
        z: initialZ,
      };

      const limits = {
        xMin: -5,
        xMax: 5,
        yMin: -5,
        yMax: 5,
      };

      targetPosition.x = Math.max(
        limits.xMin,
        Math.min(limits.xMax, targetPosition.x)
      );
      targetPosition.y = Math.max(
        limits.yMin,
        Math.min(limits.yMax, targetPosition.y)
      );

      const newTargetFocus = [0, 0, 0];

      const newCameraPosition = lerp3(
        [camera.position.x, camera.position.y, camera.position.z],
        [targetPosition.x, targetPosition.y, targetPosition.z],
        0.1
      );
      camera.position.set(...newCameraPosition);

      targetFocusRef.current = lerp3(
        targetFocusRef.current,
        newTargetFocus,
        0.1
      );
      camera.lookAt(...targetFocusRef.current);
    }
  });

  return null;
}

function Logo({ children, ...props }) {
  const nodes = useModel();

  return (
    <group {...props} dispose={null}>
      <mesh castShadow scale={[4, 4, 4]} geometry={nodes.Logo.geometry}>
        <MeshTransmissionMaterial
          samples={4}
          thickness={0.2}
          chromaticAberration={0.06}
          distortion={0.1}
          distortionScale={0.1}
          temporalDistortion={1}
          iridescence={10}
          iridescenceIOR={1}
          iridescenceThicknessRange={[0, 1400]}
        />
      </mesh>
      <group>{children}</group>
    </group>
  );
}

export default Splash;
