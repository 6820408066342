import React from "react";
import styled from "styled-components";
import Logo from "../Components/Logo";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import Tooltip from "@mui/material/Tooltip";
import { styled as muiStyled } from "@mui/material/styles";
import { useResponsive } from "../Components/responsive";

// Custom styled Tooltip
const LightBlueTooltip = muiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: transparent;
    color: black;
    font-size: 1rem;
    border: 2px solid #000;
  }
`;

const Header = () => {
  const { isXsm, isSm, isMd, isLg, isXl, is2Xl, isAbove2Xl } = useResponsive();

  return (
    <HeaderContainer>
      <LogoContainer
        $isXsm={isXsm}
        $isSm={isSm}
        $isMd={isMd}
        $isLg={isLg}
        $isXl={isXl}
        $is2Xl={is2Xl}
      >
        <a href="/">
          <Logo size="100%" fill="black" />
        </a>
        <HeaderTitle
          $isXsm={isXsm}
          $isSm={isSm}
          $isMd={isMd}
          $isLg={isLg}
          $isXl={isXl}
          $is2Xl={is2Xl}
        >
          Creative<span>ly Simple</span> VFX Workflows
        </HeaderTitle>
      </LogoContainer>
      {(isLg || isXl || is2Xl || isAbove2Xl) && (
        <MenuContainer>
          <a href="/about">
            <LightBlueTooltip title="ABOUT US">
              <StyledEmojiEmotionsOutlinedIcon />
            </LightBlueTooltip>
          </a>
          {/* <a href="/services">
            <LightBlueTooltip title="OUR SERVICES">
              <StyledAssignmentOutlinedIcon />
            </LightBlueTooltip>
          </a> */}
          <a href="/contact">
            <LightBlueTooltip title="CONTACT US">
              <StyledEmailOutlinedIcon />
            </LightBlueTooltip>
          </a>
        </MenuContainer>
      )}
      {/* <BreakpointInfo>
        <h1>{`Current Breakpoint: ${currentBreakpoint()} - Width: ${width}px`}</h1>
      </BreakpointInfo> */}
    </HeaderContainer>
  );
};

const HeaderContainer = styled.header`
  width: 100%;
  position: absolute; /* Change to absolute */
  top: 0; /* Ensure it stays at the top */
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  color: #fff;
  padding: 2rem 2rem;
  position: relative;
  z-index: 10;
`;

const LogoContainer = styled.div`
  width: ${(props) =>
    props.$isXsm
      ? "100%"
      : props.$isSm
      ? "100%"
      : props.$isMd
      ? "100%"
      : props.$isLg
      ? "60%"
      : props.$isXl
      ? "40%"
      : "40%"};
  a {
  }
`;

const HeaderTitle = styled.h1`
  font-size: ${(props) =>
    props.$isXsm
      ? "1.25rem"
      : props.$isSm
      ? "1.25rem"
      : props.$isMd
      ? "1.25rem"
      : props.$isLg
      ? "1.25rem"
      : props.$isXl
      ? "1.5rem"
      : "1.5rem"};
  text-align: ${(props) =>
    props.$isXsm || props.$isSm || props.$isMd ? "center" : "left"};
  color: #000;
  font-weight: 500;
  span {
    text-decoration: line-through;
  }
  margin-top: ${(props) =>
    props.$isXsm || props.$isSm || props.$isMd ? "1rem" : "0.5rem"};
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  gap: 2rem;
  a {
    display: inline-block;
  }
`;

const StyledEmailOutlinedIcon = styled(EmailOutlinedIcon)`
  color: black;
  font-size: 2rem; /* Adjust the size as needed */
  cursor: pointer;
`;

const StyledEmojiEmotionsOutlinedIcon = styled(EmojiEmotionsOutlinedIcon)`
  color: black;
  font-size: 2rem; /* Adjust the size as needed */
  cursor: pointer;
`;

export default Header;
